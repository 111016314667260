import('./bootstrap')

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface String {
  toCapitalize(): string
}

String.prototype.toCapitalize = function () {
  return this.toLowerCase().replace(/^(.)/g, function (a) {
    return a.toUpperCase()
  })
}
